/*! purgecss start ignore */
@import "tailwindcss/base";

/** Import everything from autoload */
@import "~swiper/swiper-bundle";

@import "tailwindcss/components";
/*! purgecss end ignore */

/*! purgecss start ignore */
#navigation {
  background-color: rgba(54,167,233, 0.95);
}

#fast-facts {
  background-color: rgba(226,244,253, 0.95);
}

.fixed-item {
  .fixed-toggle:checked ~ & {
    transform: translateY(100%);
  }
}

.navigation-close {
  font-size: 3.75rem;
}

.hero {
  min-height: 16rem;
}

.hero-bg {
  background-size: 1269px 639px;
}

.swiper-pagination-bullet {
  @apply h-3 w-3 opacity-100 transition-colors duration-300;

  background-color: #6ecff6;
}

.swiper-pagination-bullet-active {
  background-color: #36a7e9;
}

.slider-image {
  left: 16.666%;
  transform: translateX(-50%);

  @screen sm {
    left: 12.5%;
  }
}

.headline {
  @apply text-6xl;

  @screen md {
    font-size: 5.3125rem;
  }

  &.font-hand,
  .font-hand & {
    .alt-hand-font & {
      @apply text-4xl;
  
      @screen md {
        font-size: 3.5rem;
      }
    }
  }
}

.menu-item-family_tree {
  max-width: 24rem;
}

.menu-item-fast_facts {
  max-width: 18rem;
}

.fabry-facts-fixed-content {
  > p {
    @apply text-blue-light text-lg mb-2;

    &:not(:first-child) {
      @apply mt-4;
    }
  }

  > ul {
    @apply list-disc pl-4 text-sm;

    li {
      @apply mb-2;
    }
  }
}

.references {
  ol {
    counter-reset: list;

    li {
      @apply inline;

      &::before {
        @apply mr-1;

        content: counter(list) ".";
        counter-increment: list;
      }
    }
  }
}

.alt-font {
  @apply font-alt;

  .font-hand {
    @apply font-alt;
  }
}

.alt-font-2 {
  @apply font-alt-2;
}

.alt-hand-font {
  .font-hand {
    @apply font-hand-alt;
  }
}

.alt-hand-font-2 {
  .font-hand {
    @apply font-hand-alt-2;
  }
}

.alt-font,
.alt-hand-font {
  .font-hand {
    &.text-5xs,
    .text-5xs,
    &.text-4xs,
    .text-4xs,
    &.text-3xs,
    .text-3xs {
      @apply text-5xs;
    }
    
    &.text-2xs,
    .text-2xs {
      @apply text-4xs;
    }
    
    &.text-xs,
    .text-xs {
      @apply text-3xs;
    }
    
    &.text-sm,
    .text-sm {
      @apply text-2xs;
    }
    
    &.text-base,
    .text-base {
      @apply text-xs;
    }
    
    &.text-lg,
    .text-lg {
      @apply text-sm;
    }
    
    &.text-xl,
    .text-xl {
      @apply text-base;
    }
    
    &.text-2xl,
    .text-2xl {
      @apply text-lg;
    }
    
    &.text-3xl,
    .text-3xl {
      @apply text-xl;
    }
    
    &.text-4xl,
    .text-4xl {
      @apply text-2xl;
    }
    
    &.text-5xl,
    .text-5xl {
      @apply text-3xl;
    }
    
    &.text-6xl,
    .text-6xl,
    &.headline,
    .headline {
      @apply text-4xl;
    }
  }

  &.language-el {
    .font-hand {
      &.text-5xs,
      .text-5xs,
      &.text-4xs,
      .text-4xs,
      &.text-3xs,
      .text-3xs {
        @apply text-5xs;
      }
      
      &.text-2xs,
      .text-2xs {
        @apply text-5xs;
      }
      
      &.text-xs,
      .text-xs {
        @apply text-4xs;
      }
      
      &.text-sm,
      .text-sm {
        @apply text-3xs;
      }
      
      &.text-base,
      .text-base {
        @apply text-2xs;
      }
      
      &.text-lg,
      .text-lg {
        @apply text-xs;
      }
      
      &.text-xl,
      .text-xl {
        @apply text-sm;
      }
      
      &.text-2xl,
      .text-2xl {
        @apply text-base;
      }
      
      &.text-3xl,
      .text-3xl {
        @apply text-lg;
      }
      
      &.text-4xl,
      .text-4xl {
        @apply text-xl;
      }
      
      &.text-5xl,
      .text-5xl {
        @apply text-2xl;
      }
      
      &.text-6xl,
      .text-6xl,
      &.headline,
      .headline {
        @apply text-3xl;
      }
    }
  }
}

.md {
  @apply text-sm;

  .language-kr & {
    @apply text-lg;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    &:not(:last-child) {
      @apply mb-4;
    }
  }

  h1,
  h2,
  h3 {
    @apply text-blue-light;
  }

  h1,
  h2 {
    @apply text-4xl leading-none;
  }

  h3,
  h4 {
    @apply text-lg;

    .language-kr & {
      @apply text-xl;
    }
  }

  ul,
  ol {
    @apply pl-4;

    ul,
    ol {
      @apply mt-2;
    }

    li {
      &:not(:last-child) {
        @apply mb-2;
      }
    }
  }

  ul {
    @apply list-disc;

    ul {
      @apply list-circle;
    }
  }

  ol {
    @apply list-decimal;
  }

  hr {
    @apply mb-3 border-blue-light;

    &:not(:first-child) {
      @apply mt-3;
    }
  }

  a {
    &:not(.button) {
      @apply transition-colors duration-150;

      &::after {
        content: "\00a0>";
      }

      &:hover,
      &:focus {
        @apply text-blue-light;
      }
    }

    &:not(.font-normal) {
      @apply font-bold;
    }

    &:not(.no-underline) {
      @apply underline;
    }
  }

  table {
    @apply mb-6 border-black border-l border-t;

    th,
    td {
      @apply text-left px-2 py-1 border-black border-r border-b;
    }
  }
}

.what-is-fabry-diagram {
  ul,
  ol {
    li {
      margin-bottom: 0 !important;
    }
  }

  @screen lg {
    background-image: url('../../assets/fabry-symptoms-diagram.png');
    background-size: auto 85%;
  }
}

.button {
  @apply relative bg-no-repeat;

  background-image: url('../../assets/black-arrow.png');
  background-position: right 1rem center;
  background-size: 0.375em;
}

.button.dark {
  background-image: url('../../assets/white-arrow.png');
}

.fast-facts-home-hero {
  font-size: 3.125rem;

  strong {
    @apply text-blue-light;
  }
}

.template-your-family-and-fabry,
.template-person-with-fabry {
  background-color: #f5f5f5;
}

.resource-headline {
  min-height: 3.5rem;
}

.carcat {
  font-size: 4.375rem;
  line-height: 3.75rem;
  color: #aaaaaa;

  span {
    @apply block text-blue-light;
  }
}

.accordion-panel {
  @apply overflow-hidden;

  max-height: 0;
  transition: max-height 300ms;
}

.home-hero-footer {
  h2 {
    @apply text-4xl font-light;

    color: #abe2fa;
  }

  h3 {
    @apply text-blue-light;

    font-size: 1.375rem;
  }

  strong {
    @apply font-bold text-blue-light;
  }
}

.tree-iframe {
  @apply hidden;

  height: 850px;
  max-height: 736px;

  @screen lg {
    @apply block;
  }
}

.ie-hide {
  .ie & {
    @apply hidden;
  }
}

.ie-show {
  @apply hidden;

  .ie & {
    @apply block;
  }
}

.modal {
  @apply hidden;

  &.is-open {
    @apply block;
  }
}

.modal-title {
  @apply text-xl text-blue mb-2 uppercase font-thin tracking-wide;

  @screen sm {
    @apply text-4xl;
  }
}

.modal-paragraph {
  @apply text-base;
}

.modal-overlay {
  @apply fixed z-50 inset-0 flex justify-center items-center;

  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(18px);
}

.modal-dialog {
  @apply bg-white w-full max-w-2xl mx-4 max-h-screen overflow-y-auto;
}

.modal-header {
  @apply px-6 py-8;

  @screen lg {
    @apply px-8;
  }
}

.modal-footer {
  @apply px-6 py-8;

  background-color: #f5f5f5;

  @screen lg {
    @apply px-8;
  }
}

.modal-content {
  @apply px-5 mb-3;

  @screen sm {
    @apply mb-4;
  }
}

/* Markdown-Notices Plugin styling */
div.notices > p {
  @apply text-base;
}

div.notices > p:last-child {
  @apply mb-0;
}

div.notices.indigo {
  @apply bg-indigo-100;
  @apply border-indigo-200;
}

div.notices.green {
  @apply bg-green-100;
  @apply border-green-200;
}

div.notices.yellow {
  @apply bg-yellow-100;
  @apply border-yellow-200;
}

div.notices.red {
  @apply bg-red-100;
  @apply border-red-200;
}
/*! purgecss end ignore */

@import "tailwindcss/utilities";
